import React from "react";
import gradientBg from "../../../assets/gradientbg.png";
import SectionHeader from "../../Common/Wrapper/SectionHeader";
import { faqData } from "../../Common/DamyData/Data";
const FAQ = () => {
  return (
    <div className="w-[380px] lg:w-[1036px] mx-auto">
      <SectionHeader
        title="frequently asked your question"
        subTitle="Let’s know your questions"
        mainTag="FAQs"
      />
      <div className="w-[380px] lg:w-[1034px] mx-auto mt-[80px] relative">
        <div
          className="absolute -top-[54%] left-[60%] w-[234%] -translate-x-1/2 md:-top-[46%] md:w-[138%] lg:-top-[9%] hidden lg:block"
          style={{
            width: "600px",
            background: "#111111",

            opacity: "0.05px",
          }}>
          <img
            src={gradientBg}
            alt=""
            className="w-full"
            style={{ filter: "blur(140px)" }}
            height={1800}
            width={1440}
          />
        </div>
        <div className="join join-vertical w-full">
          {faqData.map?.((e, i) => {
            return (
              <div className="collapse collapse-arrow join-item border-0 border-b border-base-300 pb-2">
                <input type="radio" name="my-accordion-4" defaultChecked />
                <div className="collapse-title text-[18px] font-semibold">
                  {e?.question}
                </div>
                <div className="collapse-content">
                  <p className="pl-[7px]">{e?.answer}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
