import React from "react";
const BlogCard = ({ data }) => {
  return (
    <div
      className="h-[523px] w-[313px] lg:w-[400px] rounded-[10px]"
      style={{
        background:
          "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)",
        border: "1px solid #FFFFFF26",
      }}>
      <div className="h-[331px] w-[313px] lg:w-[400px] rounded-t-[10px] border border-[#FFFFFF12]">
        <img
          src={data?.img}
          alt=""
          className="w-full h-full object-fill rounded-t-[10px] border border-[#FFFFFF12]"
        />
      </div>
      <div className="px-[12px] lg:px-[24px] mt-[24px]">
        <div className="flex justify-between items-center">
          <p className="text-[16px] lg:text-xl font-semibold">{data?.title}</p>
          <p className="text-[14px] lg:text-[16px] font-normal text-[#D7D7D7]">
            Created: {data?.dateCreated}
          </p>
        </div>
        <p className="text-[14px] lg:text-[18px] font-normal text-[#D7D7D7] mt-[8px]">
          {data?.description}
        </p>
        <button className="mt-[21px] text-[14px] lg:text-[16px] font-semibold text-[#EEEEEE] underline hover:text-blue-500">
          Read More
        </button>
      </div>
    </div>
  );
};

export default BlogCard;
