import React, { useState } from "react";
import Slider from "react-slick";
import { PiStarFourFill } from "react-icons/pi";
import { Switch } from "antd";
import gradientBg from "../../../../assets/gradientbg.png";
import {
  pricingData,
  pricingDataMonthly,
  pricingDataYearly,
} from "../../../Common/DamyData/Data";
import PricingCard from "../../../Common/Cards/PricingCard";
import SectionHeader from "../../../Common/Wrapper/SectionHeader";
import MobilePricing from "./MobilePricing";

const Pricing = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [isMonthly, setIsMonthly] = useState(true);
  const onChange = (checked) => {
    setIsMonthly(checked);
  };

  return (
    <div
      className="w-[380px] lg:w-[1383px] mx-auto my-[140px] lg:my-[240px]"
      id="pricing">
      <SectionHeader
        title="choose your plan"
        subTitle="Make a strategic decision for your "
        mainTag="Business"
      />
      <div className="flex justify-center items-center gap-2 mt-[62px]">
        <p
          className={`text-xl font-medium leading-[24.2px] ${
            isMonthly ? "text-white" : "text-gray-500"
          }`}>
          Monthly
        </p>
        <Switch defaultChecked={isMonthly} onChange={onChange} />
        <p
          className={`text-xl font-medium leading-[24.2px] ${
            !isMonthly ? "text-white" : "text-gray-500"
          }`}>
          Yearly
        </p>
      </div>

      {isMonthly ? (
        <div className="w-[376px] lg:w-[1383px] mx-auto mt-[32px] hidden lg:grid grid-cols-1 lg:grid-cols-4 gap-0 lg:gap-[15px] gap-y-5 justify-items-center relative">
          <div
            className="absolute -top-[54%] left-[60%] w-[234%] -translate-x-1/2 md:-top-[46%] md:w-[138%] lg:-top-[5%] hidden lg:block"
            style={{
              width: "600px",
              background: "#111111",
              opacity: "0.05px",
            }}>
            <img
              src={gradientBg}
              alt=""
              className="w-full"
              style={{ filter: "blur(140px)" }}
              height={1800}
              width={1440}
            />
          </div>
          {pricingDataMonthly?.map((e, i) => {
            return <PricingCard key={i} data={e} />;
          })}
        </div>
      ) : (
        <div className="w-[376px] lg:w-[1383px] mx-auto mt-[32px] lg:grid grid-cols-1 lg:grid-cols-4 gap-0 lg:gap-[15px] gap-y-5 relative hidden">
          <div
            className="absolute -top-[54%] left-[60%] w-[234%] -translate-x-1/2 md:-top-[46%] md:w-[138%] lg:-top-[5%] hidden lg:block"
            style={{
              width: "600px",
              background: "#111111",
              opacity: "0.05px",
            }}>
            <img
              src={gradientBg}
              alt=""
              className="w-full"
              style={{ filter: "blur(140px)" }}
              height={1800}
              width={1440}
            />
          </div>
          {pricingDataYearly?.map((e, i) => {
            return <PricingCard key={i} data={e} />;
          })}
        </div>
      )}
      {/* <MobilePricing /> */}
      <div className="block lg:hidden w-[360px] lg:w-[1383px] mt-[70px] mb-[200px] mx-auto">
        <div className="slider-container mt-5">
          <Slider {...settings}>
            {pricingDataYearly?.map((e, i) => {
              return <PricingCard key={i} data={e} />;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
