import React from "react";

const PricingCard = ({ data }) => {
  console.log("data", data);
  return (
    <div
      className="w-[340px] h-[569px] rounded-[10px] px-[21px] py-[30px] z-10"
      style={{
        background:
          "linear-gradient(137.34deg, rgba(27, 28, 54, 0.16) 23.98%, rgba(112, 113, 122, 0.16) 65.73%)",
        border: "1px solid #FFFFFF26",
      }}>
      <p
        className={`text-[18px] font-bold ${
          data?.label == "Premium" ? "text-[#2244FF]" : "text-[#D7D7D7]"
        }`}>
        {data?.label}
      </p>
      <p className="text-[42px] font-bold text-[#D7D7D7]">{data?.price}</p>
      <p className="text-[18px] font-normal text-[#D7D7D7]">
        {data?.description}
      </p>

      {data?.buttonText == "Start free trial" ? (
        <button className="w-[160px] h-[52px] bg-[#2244FF] rounded-[4px] text-[18px] font-medium mt-[47px] flex justify-center items-center">
          {data?.buttonText}
        </button>
      ) : (
        <div className="relative w-[160px] h-[52px] rounded-md bg-gradient-to-tr from-[#FFFFFF] to-[#2244FF] p-0.5 shadow-lg  mt-[47px]">
          <div className="bg-[#16171A] px-[15px]  lg:px-[22px]  py-[8px] lg:py-[11px] rounded-[4px] cursor-pointer flex justify-start items-center">
            <p className="font-medium text-[18px] text-nowrap text-center">
              {data?.buttonText}
            </p>
          </div>
        </div>
      )}

      <p className="text-[16px] font-bold text-[#D7D7D7] mt-[15px] leading-[28px]">
        What’s included:
      </p>

      <div className="text-[18px] font-bold text-[#EEEEEE] mt-[15px] leading-[34px]">
        {data?.includedItems.map((item, index) => (
          <React.Fragment key={index}>
            {item}
            {index !== data.includedItems.length - 1 && <br />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default PricingCard;
