import React from "react";
import Slider from "react-slick";
import { blogData } from "../../Common/DamyData/Data";
import BlogCard from "../../Common/Cards/BlogCard";
import SectionHeader from "../../Common/Wrapper/SectionHeader";
const Blog = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="lg:block hidden w-[360px] lg:w-[1383px] my-[200px] mx-auto">
        <SectionHeader
          title="recent news"
          subTitle="Write your blog with AI"
          mainTag="Features"
        />
        <div className="mt-[78px] grid grid-cols-1 lg:grid-cols-3 gap-5">
          {blogData?.map((e, i) => {
            return <BlogCard key={i} data={e} />;
          })}
        </div>
      </div>
      <div className="block lg:hidden w-[360px] lg:w-[1383px] my-[200px] mx-auto">
        <SectionHeader
          title="recent news"
          subTitle="Write your blog with AI"
          mainTag="Features"
        />

        <div className="slider-container mt-5">
          <Slider {...settings}>
            {blogData?.map((e, i) => {
              return <BlogCard key={i} data={e} />;
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Blog;
